import { useEffect, useState } from 'react';
import { Box, Stack, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../../components/table';
import useTable from '../../../../../../hooks/useTable';
import AdminTomanTransactionToolbar from './adminTomanTransactionToolbar';
import AdminTomanTransactionTableRow from './adminTomanTransactionTableRow';
import AdminApi from "../../../../../../http/adminHttp/serviceApiAdmin";


const TABLE_HEAD = [
  { id: 'accountNumber', label: 'Account number', align: 'center' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'depositType', label: 'Deposit type', align: 'left' },
  { id: 'firstName', label: 'First name', align: 'left' },
  { id: 'lastName', label: 'Last name', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'trackingCode', label: 'Tracking code', align: 'center' },
  { id: 'userId', label: 'User ID', align: 'center' },
  { id: 'stripTxid', label: 'StripTxid', align: 'center' },
  { id: 'fiatType', label: 'Fiat type', align: 'left' },
];

export default function AdminTomanTransactionTab({userId}) {
  const { t } = useTranslation();

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState([]);

  const [conditionFilter, setConditionFilter] = useState('active');
  const [bankFilter, setBankFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [amountFilter, setAmountFilter] = useState([10000, 23000]);
  const [regardingFilter, setRegardingFilter] = useState('regarding');


  const [fiatStatusFilter, setFiatStatusFilter] = useState('ALL'); //ALL, CANCELED, FAILURE, NEW, SUCCESS
  const [dateFromFilter, setDateFromFilter] = useState(new Date(new Date().setMonth(new Date().getMonth() - 12)));
  const [dateToFilter, setDateToFilter] = useState(new Date());


  const fetchData = async () => {
    setTableData([]);

    const filters = {
      userId,
      endTime: dateToFilter?.valueOf?.(),
      startTime: dateFromFilter?.valueOf?.(),
      status:fiatStatusFilter
    };

    try {
      const response = await AdminApi.adminFiatTransaction(page, rowsPerPage, filters);
      if (response?.status === 'SUCCESS') {
        setTableData(response?.transactionInfos);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    rowsPerPage,
    fiatStatusFilter,
    dateFromFilter,
    dateToFilter,
  ]);

  return (
    <>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={500} sx={{ mt: 5 }}>
          {t('Wallet transactions')}
        </Typography>

        <Typography variant='body3' color={'grey.100'}>
          {t('User wallet transactions that have been changed by this admin')}
        </Typography>
      </Stack>

      <AdminTomanTransactionToolbar
        fiatStatusFilter={fiatStatusFilter}
        setFiatStatusFilter={setFiatStatusFilter}
        dateFromFilter={dateFromFilter}
        setDateFromFilter={setDateFromFilter}
        dateToFilter={dateToFilter}
        setDateToFilter={setDateToFilter}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <AdminTomanTransactionTableRow key={row.id} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </>
  );
}
